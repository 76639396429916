<template>
    <div :class="rootClasses">
        <div class="container">
            <div class="list-card">
                <div class="row">
                    <div v-for="(item, key) in data.cards" :key="key" class="col-12 col-sm-6 col-lg-4 no-pd">
                        <div class="list-card__item">
                            <div class="img">
                                <img :src="item.image" alt="">
                            </div>
                            <div class="info">
                                <h6>{{ item.title }}</h6>
                                <p>{{ item.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IconsCards',
    components: {},
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    computed: {
        rootClasses() {
            let result = {
                ['cs-icons-cards']: true,
                ['theme-block']: true
            }

            if (this.data?.hasBackground)
                result = {
                    ...result,
                    ...{
                        ['has-background']: true,
                        ['theme-bg-colored']: true
                    }
                }

            return result
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/main.scss';

::v-deep {
    .row{
        justify-content: center;
        .no-pd{
            @media (min-width: 576px ) {
                 padding: 0 !important;
            }
        }
    }
    .list-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
        }

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $primary_text_color;
            padding: 25px;
            border-radius: 2px;
            cursor: pointer;
            @include base-transition;

            &:hover {
                background: #fff;
                box-shadow: 0px 7px 29px rgba(28, 28, 28, 0.13);
            }

            .img {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
            }

            .info {
                h6 {
                    font-family: 'Montserrat';
                    font-weight: bold;
                    font-size: 18px;
                    color: #313131;
                    margin-bottom: 0px;
                    margin-top: 15px;
                    text-align: center;
                    word-wrap: break-word;
                }

                p {
                    word-wrap: break-word;
                    font-family: 'Montserrat';
                    font-weight: normal;
                    font-size: 16px;
                    color: #313131;
                    margin-bottom: 0px;
                    margin-top: 15px; text-align: center;
                }
            }
        }
    }
}
</style>
